import React, { useEffect, useState, useContext } from "react";
import "../../../styles/login.css";
import Sidebar from "../../../components/Admin/Sidebar/Sidebar";
import { useParams } from "react-router-dom";
import moment from "moment";
import { AuthContext } from "../../../context/AuthContext";
import HandleErrors from "../../../utils/handleErrors";

import { fetchData, editData, deleteData } from "../../../utils/functions";

import { useNavigate } from "react-router-dom";

const SingleClient = () => {
  const [medicine, setMedicine] = useState([]);
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const [formData, setFormData] = useState({
    name: undefined,
    purpose: undefined,
    type: undefined,
    prompt: undefined,
    updatedBy: user?.userData?.name || "indefinido",
  });

  useEffect(() => {
    fetchData("medicines", id, setMedicine, setIsLoading, setError);
  }, [id]);

  // Functions

  const notifySaveChanges = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 10000);
  };

  const navigate = useNavigate();

  const del = () => {
    deleteData(id, "medicines", navigate);
  };

  const edit = () => {
    medicine.updatedBy = user.userData.name;
    editData(id, "medicines", medicine);
    notifySaveChanges();
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const filteredMedicine = medicine;

  return (
    <>
      <div
        className="admin"
        id="admin"
        style={{
          background: "url('../../assets/img/home.png') no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >

        <Sidebar />

        <main id="main" className="main">
          {showNotification && (
            <div
              className="alert alert-info alert-dismissible fade show"
              style={{ position: "fixed", top: "80px", zIndex: 999 }}
              role="alert"
            >
              <i className="bi bi-info-circle me-1"></i>Alterações feitas!
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              ></button>
            </div>
          )}
          <div className="pagetitle">
            <h1>Visualização</h1>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="/admin">Início</a>
                </li>
                <li className="breadcrumb-item">
                  <a href="/medicines">Medicamentos</a>
                </li>
                <li className="breadcrumb-item active">Painel</li>
              </ol>
            </nav>
          </div>
          <div class="container" style={{ marginTop: 0 }}>
            <section class="section register min-vh-100 d-flex flex-column align-items-center">
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-md-6 d-flex flex-column align-items-center justify-content-center">
                    {isLoading ? (
                      <></>
                    ) : (
                      <div class="card mb-3">
                        <div class="card-body">
                          <div class="pt-4 pb-2">
                            <h5 class="card-title text-center pb-0 fs-4">
                              Visualização
                            </h5>
                            <p class="text-center small">Medicamentos</p>
                          </div>
                          {error && <HandleErrors err={error}></HandleErrors>}
                          <form class="row g-3" method="put">
                            <div class="col-6">
                              <label for="name" class="form-label">
                                Medicamento
                              </label>
                              <div class="input-group">
                                <input
                                  type="text"
                                  name="name"
                                  defaultValue={filteredMedicine?.name}
                                  onChange={handleChange}
                                  class="form-control"
                                  id="name"
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-12">
                              <label htmlFor="prompt" className="form-label">
                                Prompt
                              </label>
                              <div className="input-group">
                                <input
                                  type="text"
                                  name="prompt"
                                  className="form-control"
                                  defaultValue={filteredMedicine?.prompt}
                                  onChange={handleChange}
                                  placeholder="Escreva o prompt"
                                  id="prompt"
                                  autoComplete="false"
                                  required
                                />
                              </div>
                            </div>

                            <div className="col-12">
                              <label htmlFor="purpose" className="form-label">
                                Propósito
                              </label>
                              <div className="input-group">
                                <select
                                  name="purpose"
                                  id="purpose"
                                  className="form-control"
                                  value={filteredMedicine.purpose}
                                  onChange={handleChange}
                                >
                                  <option value={filteredMedicine.purpose}>
                                    {filteredMedicine.purpose}
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div className="col-12">
                              <label htmlFor="type" className="form-label">
                                Tipo
                              </label>
                              <div className="input-group">
                                <select
                                  name="type"
                                  id="type"
                                  className="form-control"
                                  value={filteredMedicine.type}
                                  onChange={handleChange}
                                >
                                  <option value={filteredMedicine.type}>
                                    {filteredMedicine.type}
                                  </option>
                                </select>
                              </div>
                            </div>

                            <div className="col-4">
                              <label htmlFor="createdAt" className="form-label">
                                Created at
                              </label>
                              <div className="input-group">
                                <p
                                  name="createdAt"
                                  className="form-control"
                                  id="createdAt"
                                >
                                  {moment(filteredMedicine?.createdAt).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="col-4">
                              <label htmlFor="updatedAt" className="form-label">
                                Updated at
                              </label>
                              <div className="input-group">
                                <p
                                  name="updatedAt"
                                  className="form-control"
                                  id="updatedAt"
                                >
                                  {moment(filteredMedicine?.updatedAt).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="col-4">
                              <label htmlFor="status" className="form-label">
                                Status
                              </label>
                              <div class="input-group">
                                <input
                                  className="form-control"
                                  name="status"
                                  defaultValue={filteredMedicine?.status}
                                  onChange={handleChange}
                                  id="status"
                                />
                              </div>
                            </div>

                            <div class="col-4">
                              <button
                                class="btn btn-secondary w-100"
                                onClick={edit}
                                type="button"
                              >
                                Editar
                              </button>
                            </div>
                            <div class="col-4">
                              <button
                                type="button"
                                class="btn btn-danger w-100"
                                onClick={del}
                              >
                                Deletar
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </div>
    </>
  );
};

export default SingleClient;
