import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import { maskBr } from "js-brasil";

import "moment/locale/pt-br";
moment.locale("pt-br");

const styles = StyleSheet.create({
  page: {
    padding: "40 50",
  },

  pageBackground: {
    position: "absolute",
    minWidth: "100vw",
    minHeight: "100vh",
    display: "block",
    height: "100%",
    width: "100%",
  },

  row: {
    display: "flex",
    flexWrap: "wrap",
  },

  col: {
    flex: "0 0 auto",
    width: "100%",
    margin: "5 0",
  },

  h2: {
    color: "#000",
    fontSize: 30,
    margin: "5 0",
  },

  h3: {
    color: "#222",
    margin: "5 0",
    fontSize: 13,
  },

  p: {
    marginTop: 5,
    color: "#777",
    fontSize: 12,
  },
  bulletPoint: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 5,
  },
  bullet: {
    width: 5,
    height: 5,
    borderRadius: 10,
    backgroundColor: "#000",
    marginRight: 5,
  },
});

const MyDocument = ({ prescription }) => (
  <Document>
    <Page size="A4">
      <View style={styles.page}>
        <Image
          src={"/assets/img/certificate/background.png"}
          style={styles.pageBackground}
        />
        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={[styles.h2, { textAlign: "center" }]}>Receita</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={styles.p}>Paciente: {prescription?.client?.name}</Text>
            <Text style={styles.p}>
              CPF: {maskBr.cpf(prescription?.client?.identity)}
            </Text>
            <Text style={styles.p}>Dentista: {prescription?.user?.name}</Text>
          </View>
          <View style={[styles.col, { textAlign: "right" }]}>
            <Text style={styles.p}>ID da Receita</Text>
            <Text style={styles.p}>{prescription?._id}</Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.col}>
            {prescription?.medicines?.map((medicine, index) => (
              <>
                <View key={index} style={styles.bulletPoint}>
                  <View style={styles.bullet} />
                  <Text style={styles.p}>{medicine?.name}</Text>
                </View>
                <Text style={styles.p}>{medicine?.prompt}</Text>
              </>
            ))}
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={[styles.p, { textAlign: "right", marginTop: 20 }]}>
              Marataízes,{" "}
              {moment(prescription?.updatedAt).format("DD [de] MMMM [de] YYYY")}
            </Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default MyDocument;
