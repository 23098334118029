import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "./../../utils/config";
import { AuthContext } from "../../context/AuthContext";
import Cookies from "js-cookie";
import axios from "axios";
import HandleErros from "../../utils/handleErrors";

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: undefined,
    password: undefined,
  });
  const [error, setError] = useState(false);

  const { dispatch } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch({ type: "LOGIN_START" });

    try {
      const res = await axios.post(`${BASE_URL.key}/auth/login`, formData, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result = res.data;

      Cookies.set("accessToken", result.data.token);

      dispatch({ type: "LOGIN_SUCCESS", payload: result.data });
      navigate("/admin");
    } catch (err) {
      dispatch({ type: "LOGIN_FAILURE", payload: err.response.data.message });
      setError(err);
    }
  };

  return (
    <>
      <div class="container" style={{ marginTop: "40px" }}>
        <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                <div class="d-flex justify-content-center py-4">
                  <a
                    href="/login"
                    class="logo d-flex align-items-center w-auto"
                  >
                    <img src="assets/img/logo.png" alt="" />
                    <span class="d-none d-lg-block">Mend's Clin</span>
                  </a>
                </div>

                <div class="card mb-3">
                  <div class="card-body">
                    <div class="pt-4 pb-2">
                      <h5 class="card-title text-center pb-0 fs-4">Login</h5>
                      <p class="text-center small">
                        Apenas para administradores
                      </p>
                    </div>

                    {error && <HandleErros err={error}></HandleErros>}

                    <form class="row g-3" method="post" onSubmit={handleSubmit}>
                      <div class="col-12">
                        <label for="email" class="form-label">
                          Email
                        </label>
                        <div class="input-group">
                          <span class="input-group-text" id="inputGroupPrepend">
                            @
                          </span>
                          <input
                            type="email"
                            name="email"
                            class="form-control"
                            value={formData.email}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                email: e.target.value,
                              })
                            }
                            id="email"
                            required
                          />
                        </div>
                      </div>

                      <div class="col-12">
                        <label for="password" class="form-label">
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          class="form-control"
                          value={formData.password}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              password: e.target.value,
                            })
                          }
                          id="password"
                          required
                        />
                      </div>

                      <div class="col-12">
                        <button class="btn btn-primary w-100" type="submit">
                          Login
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Login;
