import React, { useState, useEffect, useContext } from "react";
import { getData } from "../../../utils/functions";
import { Box, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import { AuthContext } from "../../../context/AuthContext";
import { tokens } from "../../../theme";
import Header from "../../../components/Admin/Title/Title";
import NumberTotal from "../../../components/Admin/Cards/NumberTotal";
import Admin from "../Admin";
import CustomToolbar from "../../../utils/CustomToolbar.js";
import Calendar from "../../../components/Admin/Calendar/Calendar.jsx";
import { formatStatus } from "../../../components/Admin/Validation/Validation.jsx";

import moment from "moment";

export default function Bookings() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(AuthContext);

  const [bookings, setBookings] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const filteredBookings = bookings.map((data, index) => ({
    ...data,
    id: index + 1,
    time: `${data.time_inicial} -:- ${data.time_final}`,
  }));

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "client",
      headerName: "Paciente",
      flex: 1,
      cellClassName: "name-column--cell",
      valueGetter: (params) => params?.name || "N/A",
    },
    {
      field: "user",
      headerName: "Dentista",
      flex: 1,
      valueGetter: (params) => params?.name || "N/A",
    },
    { field: "updatedBy", headerName: "Atualizado por", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      valueGetter: (params) => formatStatus(params),
    },
    { field: "time", headerName: "Horário", flex: 1 },
    {
      field: "data",
      headerName: "Data",
      flex: 1,
      valueGetter: (params) => moment(params).format("DD/MM/YYYY") || "N/A",
    },
    { headerName: "Ação", flex: 1 },
  ];

  useEffect(() => {
    getData("bookings", setBookings, setIsLoading, setError);
  }, []);

  const customToolbar = () => {
    return <CustomToolbar />;
  };

  return (
    <Admin>
      <Box m="20px">
        <Header
          title="Agendamentos"
          subtitle="Lista de agendamentos criados no sistema."
        />
        <Box display={"flex"}>
          <Box flex="0 0 30%">
            <NumberTotal route={"bookings"} />
          </Box>
        </Box>
        <Box
          m="40px 0 0 0"
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": {
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: "none",
            },
            "& .name-column--cell": {
              color: colors.greenAccent[300],
            },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]}`,
            },
          }}
        >
          <DataGrid
            checkboxSelection
            rows={filteredBookings}
            columns={columns}
            initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
            slots={{ toolbar: customToolbar }}
            pageSizeOptions={[5, 10, 25]}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
          />
        </Box>
        <Box mt={10}>
          <Calendar />
        </Box>
      </Box>
    </Admin>
  );
}
