import React from "react";

const ToothWithImage = ({
  number,
  condition,
  procedure,
  description,
  onClick,
  style,
}) => {
  return (
    <div
      className="tooth"
      onClick={() => onClick(number)}
      style={style}
      title={`Procedimento: ${procedure}\nDescrição: ${description}`}
    >
      <div className={`tooth-overlay ${condition}`}></div>
    </div>
  );
};

export default ToothWithImage;
