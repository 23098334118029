import React from "react";

export default function Footer() {
  return (
    <>
      <footer id="footer">
        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-6 footer-contact">
                <h3>Mend's Clin</h3>
                <p>
                  ES-060, 109 <br />
                  Alto Lagoa Funda, Marataízes
                  <br />
                  ES, 29345-000 <br />
                  <br />
                  <strong>Telefone:</strong> (28) 9.9274 - 0822
                  <br />
                  <strong>Email:</strong> mendsclin@gmail.com
                  <br />
                </p>
              </div>

              <div class="col-lg-2 col-md-6 footer-links">
                <h4>Links</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="#hero">Início</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="#hero">Sobre nós</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="#services">Serviços</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-3 col-md-6 footer-links">
                <h4>Serviços</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a href="#clareamento">Clareamento</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a href="#endodontia">Endodontia (Canal)</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a href="#implantodontia">Implantodontia</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a href="#ortodontia">Ortodontia</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a href="#protese">Prótese dentária</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a href="#restauracao">Restauração</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a href="#limpeza">Limpeza</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>
                    <a href="#clinico">Clínico dental</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-4 col-md-6 footer-newsletter">
                <h4>Nossos valores</h4>
                <p>Valorizamos a beleza e saúde do seu sorriso!</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
