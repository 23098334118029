export const formatJustification = (e) => {
  switch (e) {
    case "job":
      return "Trabalho";

    case "law":
      return "Jurídico";

    case "school":
      return "Escolar";

    case "militar":
      return "Militar";

    default:
      return "Outro";
  }
};

export const formatStatus = (e) => {
  switch (e) {
    case "active":
      return "Ativo";

    case "inactive":
      return "Inativo";

    case "confirmado":
      return "Confirmado";

    case "aguardando":
      return "Aguardando";

    case "cancelado":
      return "Cancelado";

    default:
      return "N/A";
  }
};
