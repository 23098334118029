import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
} from "@mui/material";
import medications from "./medications.json";
import typeMapping from "./type_mapping.json";

export default function UserFormDialog({ open, onClose, user }) {
  const [newMedicine, setNewMedicine] = useState({
    name: "",
    createdBy: user?.userData?.name || "indefinido",
    updatedBy: user?.userData?.name || "indefinido",
    type: "",
    status: "",
    prompt: "",
    purpose: "",
  });

  const [medicines, setMedicines] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredMedicines, setFilteredMedicines] = useState([]);

  useEffect(() => {
    const allMedicines = Object.entries(medications).flatMap(
      ([category, items]) =>
        Array.isArray(items)
          ? items.map((item) => ({ ...item, type: category }))
          : Object.values(items).flat()
    );
    setMedicines(allMedicines);
    setFilteredMedicines(allMedicines);
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewMedicine({ ...newMedicine, [name]: value });
  };

  const handleMedicineChange = (e) => {
    const selectedMedicine = e.target.value;

    const medicineDetails = medicines.find(
      (med) => med.name === selectedMedicine
    );
    if (medicineDetails) {
      setNewMedicine({
        ...newMedicine,
        name: selectedMedicine,
        type: medicineDetails.type || "não definido",
        purpose: medicineDetails.purpose || "não definido",
      });
    }
  };

  const handleSearchChange = (e) => {
    setFilteredMedicines(medicines);
    const term = e.target.value.toLowerCase();
    setSearchTerm(term);

    const mappedPurpose = medicines.filter((med) =>
      med.purpose.includes(typeMapping[term])
    );

    if (mappedPurpose.length !== 0 ) {
      setFilteredMedicines(mappedPurpose);
    }
  };

  const handleSubmit = () => {
    console.log("Novo medicamento:", newMedicine);
    onClose(); // Fecha o diálogo
    setNewMedicine({ name: "", type: "", status: "", prompt: "", purpose: "" });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Adicionar Medicamento</DialogTitle>
      <DialogContent>
        <TextField
          label="Buscar Medicamento"
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
          margin="dense"
        />
        <TextField
          select
          label="Medicamento"
          name="name"
          value={newMedicine.name}
          onChange={handleMedicineChange}
          fullWidth
        >
          {filteredMedicines.map((med, index) => (
            <MenuItem key={index} value={med.name}>
              {med.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          margin="dense"
          name="purpose"
          label="Propósito"
          type="text"
          fullWidth
          variant="outlined"
          value={newMedicine.purpose}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="type"
          label="Tipo"
          type="text"
          fullWidth
          variant="outlined"
          value={newMedicine.type}
          onChange={handleInputChange}
        />
        <TextField
          label="Prompt"
          name="prompt"
          value={newMedicine.prompt}
          onChange={handleInputChange}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit}>Adicionar</Button>
      </DialogActions>
    </Dialog>
  );
}
