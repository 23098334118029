// imports.js
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { AuthContext } from "../../../context/AuthContext";
import { PDFDownloadLink } from "@react-pdf/renderer";

import {
  fetchData,
  editData,
  getData,
  deleteData,
} from "../../../utils/functions";

// imports.jsx
import Sidebar from "../../../components/Admin/Sidebar/Sidebar";
import HandleErrors from "../../../utils/handleErrors";
import Document from "./Document";

const SingleCertificate = () => {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [certificate, setCertificates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    fetchData("certificates", id, setCertificates, setIsLoading, setError);
    getData("users", setUsers, setIsLoading, setError);
    getData("clients", setClients, setIsLoading, setError);
  }, [id]);

  const notifySaveChanges = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 10000);
  };

  const filteredUsers = users;

  const filteredClients = clients;

  const navigate = useNavigate();

  const del = () => {
    deleteData(id, "certificates", navigate);
  };

  const edit = () => {
    certificate.updatedBy = user?.userData?.name;
    editData(id, "certificates", certificate);
    notifySaveChanges();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCertificates((prevCertificate) => ({
      ...prevCertificate,
      [name]: value,
    }));
  };

  return (
    <div
      className="admin"
      id="admin"
      style={{
        background: "url('../../assets/img/home.png') no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >

      <Sidebar />

      <main id="main" className="main">
        {showNotification && (
          <div
            className="alert alert-info alert-dismissible fade show"
            style={{ position: "fixed", top: "80px", zIndex: 999 }}
            role="alert"
          >
            <i className="bi bi-info-circle me-1"></i>Alterações feitas!
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        )}
        <div className="pagetitle">
          <h1>Visualização</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin">Início</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/certificates">Atestados</a>
              </li>
              <li className="breadcrumb-item active">Painel</li>
            </ol>
          </nav>
        </div>
        <div className="container">
          <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                  {isLoading ? (
                    <></>
                  ) : (
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="pt-4 pb-2">
                          <h5 className="card-title text-center pb-0 fs-4">
                            Visualização
                          </h5>
                          <p className="text-center small">Atestado</p>
                        </div>

                        {error && <HandleErrors err={error}></HandleErrors>}

                        <form className="row g-3" action="">
                          <div className="col-6">
                            <label htmlFor="client" className="form-label">
                              Paciente
                            </label>
                            <div className="input-group">
                              <select
                                name="client"
                                id="client"
                                className="form-control"
                                value={certificate.client}
                                onChange={handleInputChange}
                              >
                                {certificate.client && (
                                  <option value={certificate?.client?._id}>
                                    {certificate?.client?.name}
                                  </option>
                                )}
                                {filteredClients
                                  .filter(
                                    (client) =>
                                      client._id !== certificate?.client?._id
                                  )
                                  .map((client) => (
                                    <option
                                      key={client?._id}
                                      value={client?._id}
                                    >
                                      {client?.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          <div class="col-6">
                            <label for="user" class="form-label">
                              Dentista
                            </label>
                            <div class="input-group">
                              <select
                                name="user"
                                id="user"
                                className="form-control"
                                value={certificate?.user}
                                onChange={handleInputChange}
                              >
                                {certificate.user && (
                                  <option value={certificate?.user?._id}>
                                    {certificate?.user?.name}
                                  </option>
                                )}
                                {filteredUsers
                                  .filter(
                                    (user) =>
                                      user._id !== certificate?.user?._id
                                  )
                                  .map((user) => (
                                    <option key={user._id} value={user._id}>
                                      {user.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-4">
                            <label htmlFor="createdAt" className="form-label">
                              Criado em
                            </label>
                            <div className="input-group">
                              <p
                                name="createdAt"
                                className="form-control"
                                id="createdAt"
                              >
                                {moment(certificate?.createdAt).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="col-4">
                            <label htmlFor="updatedAt" className="form-label">
                              Atualizado em
                            </label>
                            <div className="input-group">
                              <p
                                name="updatedAt"
                                className="form-control"
                                id="updatedAt"
                              >
                                {moment(certificate?.updatedAt).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="col-4">
                            <label
                              htmlFor="time_inicial"
                              className="form-label"
                            >
                              Retorno
                            </label>
                            <input
                              type="number"
                              name="return"
                              className="form-control"
                              value={certificate?.return}
                              onChange={handleInputChange}
                              id="return"
                              autoComplete="false"
                            />
                          </div>

                          <div class="col-4">
                            <label htmlFor="justification" class="form-label">
                              Justificativa
                            </label>
                            <div class="input-group">
                              <select
                                name="justification"
                                id="justification"
                                className="form-control"
                                value={certificate?.justification}
                                onChange={handleInputChange}
                              >
                                <option value="">
                                  Selecione uma justificação
                                </option>

                                <option value="job">Trabalho</option>
                                <option value="school">Escola</option>
                                <option value="sport">Desposte</option>
                                <option value="law">Judiciário</option>
                                <option value="military">Militar</option>
                              </select>
                            </div>
                          </div>

                          <div className="col-4">
                            <label htmlFor="cid" className="form-label">
                              CID
                            </label>
                            <input
                              type="text"
                              name="cid"
                              className="form-control"
                              value={certificate?.cid}
                              onChange={handleInputChange}
                              id="cid"
                              autoComplete="false"
                            />
                          </div>

                          <div className="col-4">
                            <label
                              htmlFor="time_inicial"
                              className="form-label"
                            >
                              Entrada
                            </label>
                            <input
                              type="time"
                              name="time_inicial"
                              className="form-control"
                              value={certificate?.time_inicial}
                              onChange={handleInputChange}
                              id="time_inicial"
                              autoComplete="false"
                            />
                          </div>

                          <div className="col-4">
                            <label htmlFor="time_final" className="form-label">
                              Saída
                            </label>
                            <input
                              type="time"
                              name="time_final"
                              className="form-control"
                              value={certificate?.time_final}
                              onChange={handleInputChange}
                              id="time_final"
                              autoComplete="false"
                            />
                          </div>

                          <div className="col-4">
                            <label htmlFor="data" className="form-label">
                              Data da consuta
                            </label>
                            <input
                              type="date"
                              name="data"
                              className="form-control"
                              value={certificate?.data?.split("T")[0]}
                              onChange={handleInputChange}
                              id="data"
                              autoComplete="false"
                            />
                          </div>

                          <div className="col-4">
                            <label
                              htmlFor="data_documento"
                              className="form-label"
                            >
                              Data do documento
                            </label>
                            <input
                              type="date"
                              name="data_documento"
                              className="form-control"
                              value={certificate?.data_documento?.split("T")[0]}
                              onChange={handleInputChange}
                              id="data_documento"
                              autoComplete="false"
                            />
                          </div>

                          <div className="col-4">
                            <button
                              className="btn btn-secondary w-100"
                              onClick={edit}
                              type="button"
                            >
                              Editar
                            </button>
                          </div>
                          <div className="col-4">
                            <button
                              className="btn btn-danger w-100"
                              onClick={del}
                              type="button"
                            >
                              Deletar
                            </button>
                          </div>
                          <div className="col-4">
                            <PDFDownloadLink
                              className="btn btn-primary w-100"
                              document={
                                <Document
                                  certificate={certificate}
                                  client={certificate?.client}
                                />
                              }
                              fileName="certificate.pdf"
                            >
                              {({ blob, loading, error }) =>
                                loading ? "Carregando..." : "Criar documento"
                              }
                            </PDFDownloadLink>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default SingleCertificate;
