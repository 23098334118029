import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import { maskBr } from "js-brasil";

import "moment/locale/pt-br";
moment.locale("pt-br");

const styles = StyleSheet.create({
  page: {
    padding: "40 50",
  },

  pageBackground: {
    position: "absolute",
    minWidth: "100vw",
    minHeight: "100vh",
    display: "block",
    height: "100%",
    width: "100%",
  },

  row: {
    display: "flex",
    flexWrap: "wrap",
  },

  col: {
    flex: "0 0 auto",
    width: "100%",
    margin: "5 0",
  },

  h2: {
    color: "#000",
    fontSize: 30,
    margin: "5 0",
  },

  h3: {
    color: "#222",
    margin: "5 0",
    fontSize: 13,
  },

  p: {
    marginTop: 5,
    color: "#777",
    fontSize: 12,
  },
});

const filteredJustification = (e) => {
  switch (e) {
    case "job":
      return "Justificativa de falta de trabalho.";
    case "school":
      return "Dispensa de atividades escolares.";
    case "millitary":
      return "Dispensa de atividades militares.";
    case "law":
      return "Dispensa de atividades judiciais.";
    case "sport":
      return "Dispensa de atividades desportivas.";
    default:
      return "Outros";
  }
};

const minor = (parents) => {
  if (parents) {
    const text = `${parents}, representante legal de `;
    return text;
  }
};

const MyDocument = ({ client, certificate }) => (
  <Document>
    <Page size="A4">
      <View style={styles.page}>
        <Image
          src={"/assets/img/certificate/background.png"}
          style={styles.pageBackground}
        />
        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={[styles.h2, { textAlign: "center" }]}>
              ATESTADO ODONTOLÓGICO
            </Text>
            <Text style={[styles.p, { textAlign: "center" }]}>
              (Regulamento pelas Leis nº. 5.081, de 24/08/66 e nº 6.215, de
              30/06/75)
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={[styles.col, { textAlign: "right", marginTop: 30 }]}>
            <Text style={styles.p}>1ª via – Paciente</Text>
            <Text style={styles.p}>2ª via – Profissional</Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={styles.h3}>Atestado específico de:</Text>
            <Text style={[styles.p, { marginBottom: 50 }]}>
              (x) {filteredJustification(certificate.justification)}
            </Text>
          </View>
        </View>
        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={styles.p}>
              a pedido de {minor(client?.parents)} {client?.name}, portador do
              CPF nº {maskBr.cpf(client?.identity)}, esteve sob meus cuidados
              profissionais, no período das {certificate?.time_inicial} às{" "}
              {certificate?.time_final} horas, no dia{" "}
              {moment(certificate?.data)
                .add(1, "day")
                .format("DD [de] MMMM [de] YYYY")}
              , devendo: Retornar às atividades normais em {certificate?.return}{" "}
              dia/s.
            </Text>
          </View>
        </View>

        <View style={styles.row}>
          <View style={styles.col}></View>
        </View>

        <View style={styles.row}>
          <View style={styles.col}>
            <Text style={[styles.p, { textAlign: "left", marginTop: 20 }]}>
              CID {certificate?.cid}
            </Text>
            <Text style={[styles.p, { textAlign: "right", marginTop: 20 }]}>
              Marataízes,{" "}
              {moment(certificate?.data_documento)
                .add(1, "day")
                .format("DD [de] MMMM [de] YYYY")}
            </Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

export default MyDocument;
