import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Grid2,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";

export default function ClientFormDialog({ open, onClose, user }) {
  const [newClient, setNewClient] = useState({
    name: "",
    createdBy: user?.userData?.name || "indefinido",
    updatedBy: user?.userData?.name || "indefinido",
    tel: "",
    email: "",
    identity: "",
    insurance: "",
    insurance_id: "",
    birthdate: "0",
    parents: "",
  });
  const [isMinor, setIsMinor] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "birthdate") {
      const birthDate = new Date(value);
      const today = new Date();
      var age = today.getFullYear() - birthDate.getFullYear();
      const month = today.getMonth() - birthDate.getMonth();

      if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }

      setIsMinor(age < 18);
    }

    setNewClient({ ...newClient, [name]: value });
  };

  const handleSubmit = () => {
    console.log("Novo Paciente:", newClient);
    onClose(); // Fecha o diálogo
    setNewClient({
      name: "",
      tel: "",
      email: "",
      identity: "",
      insurance: "",
      insurance_id: "",
      birthdate: "",
      parents: "",
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Adicionar Novo Paciente</DialogTitle>
      <DialogContent>
        <Grid2 container spacing={0.8}>
          <Grid2 item size={8}>
            <TextField
              autoFocus
              margin="dense"
              name="name"
              label="Nome"
              type="text"
              fullWidth
              variant="outlined"
              value={newClient.name}
              onChange={handleInputChange}
            />
          </Grid2>
          <Grid2 item size={4}>
            <TextField
              margin="dense"
              name="identity"
              label="Identidade"
              type="text"
              fullWidth
              variant="outlined"
              value={newClient.identity}
              onChange={handleInputChange}
            />
          </Grid2>
        </Grid2>
        <Grid2 container spacing={0.8}>
          <Grid2 item size={4}>
            <TextField
              margin="dense"
              name="phone"
              label="Telefone"
              type="text"
              fullWidth
              variant="outlined"
              value={newClient.phone}
              onChange={handleInputChange}
            />
          </Grid2>
          <Grid2 item size={8}>
            <TextField
              margin="dense"
              name="email"
              label="Email"
              type="email"
              fullWidth
              variant="outlined"
              value={newClient.email}
              onChange={handleInputChange}
            />
          </Grid2>
        </Grid2>
        <Grid2 container spacing={0.8}>
          <Grid2 item size={4}>
            <FormControl fullWidth margin="dense">
              <InputLabel>Plano</InputLabel>
              <Select
                name="insurance"
                value={newClient.insurance}
                onChange={handleInputChange}
                label="Plano"
              >
                <MenuItem value="samp">SAMP</MenuItem>
                <MenuItem value="uniodonto">Uniodonto</MenuItem>
                <MenuItem value="other">Outro</MenuItem>
              </Select>
            </FormControl>
          </Grid2>
          <Grid2 item size={4}>
            <TextField
              margin="dense"
              name="insurance_id"
              label="Plano ID"
              type="text"
              fullWidth
              variant="outlined"
              value={newClient.insurance_id}
              onChange={handleInputChange}
            />
          </Grid2>
          <Grid2 item size={4}>
            <TextField
              margin="dense"
              name="birthdate"
              label="Data de nascimento"
              type="date"
              fullWidth
              variant="outlined"
              value={newClient.birthdate}
              onChange={handleInputChange}
            />
          </Grid2>
        </Grid2>
        <Collapse in={isMinor}>
          <TextField
            margin="dense"
            name="parents"
            label="Nome completo do responsável"
            type="text"
            fullWidth
            variant="outlined"
            value={newClient.parents}
            onChange={handleInputChange}
          />
        </Collapse>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit}>Enviar</Button>
      </DialogActions>
    </Dialog>
  );
}
