import React from "react";

export default function TopBar() {
  return (
    <>
      <div id="topbar" class="d-flex align-items-center fixed-top">
        <div class="container d-flex justify-content-between">
          <div class="contact-info d-flex align-items-center">
            <i class="bi bi-phone">(28) 9.9274 - 0822</i> 
            <i class="bi bi-phone">(28) 3199 - 1600</i>
          </div>
        </div>
      </div>
    </>
  );
}
