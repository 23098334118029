import React, { useState } from "react";
import "../../styles/admin.css";
import { Box } from "@mui/material";
import Sidebar from "../../components/Admin/Sidebar/Sidebar";
import Topbar from "../../components/Admin/Topbar/Topbar.jsx";

const Admin = ({ children }) => {
  const [isSidebar, setIsSidebar] = useState(true);

  return (
    <div className="app" style={{ overflow: "hidden" }}>
      <Sidebar isSidebar={isSidebar} />
      <main className="content" style={{ overflow: "auto" }}>
        <Topbar setIsSidebar={setIsSidebar} />
        <Box m="20px">{children}</Box>
      </main>
    </div>
  );
};

export default Admin;
