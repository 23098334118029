import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";

import Home from "./../pages/Home/Home";
import Dashboard from "../pages/Admin/DashBoard";

import Login from "./../pages/Auth/Login";

import NotFoundPage from "./../pages/NotFoundPage/NotFoundPage";

import { AuthContext } from "../context/AuthContext";
import Cookies from "js-cookie";

// Atestados
import Certificates from "./../pages/Admin/Certificates/Certificates";
import SingleCertificate from "./../pages/Admin/Certificates/SingleCertificate";

// Receita
import Prescriptions from "./../pages/Admin/Prescriptions/Prescriptions";
import SinglePrescription from "./../pages/Admin/Prescriptions/SinglePrescription";

// Usuários
import Users from "./../pages/Admin/Users/Users";
import SingleUser from "./../pages/Admin/Users/SingleUser";

// Clientes
import Clients from "./../pages/Admin/Clients/Clients";
import SingleClient from "./../pages/Admin/Clients/SingleClient";

// Medicamentos
import Medicines from "./../pages/Admin/Medicines/Medicines";
import SingleMedicine from "./../pages/Admin/Medicines/SingleMedicine"

// Agendamentos
import Bookings from "./../pages/Admin/Booking/Bookings";
import SingleBooking from "./../pages/Admin/Booking/SingleBooking"

const Private = ({ Item }) => {
  const { user } = useContext(AuthContext);
  const token = Cookies.get("accessToken");

  var signed = undefined;
  if (user) {
    if (token) {
      if (user.userData.role === "admin") {
        signed = 1;
      }
    }
  } else {
    signed = 0;
  }

  return signed === 1 ? <Item /> : <Login />;
};

const Routers = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/admin" element={<Private Item={Dashboard} />} />
      
      <Route path="/users" element={<Users />} />
      <Route path="/users/:id" element={<SingleUser />} />

      <Route path="/clients" element={<Clients />} />
      <Route path="/clients/:id" element={<SingleClient />} />

      <Route path="/medicines" element={<Medicines />} />
      <Route path="/medicines/:id" element={<SingleMedicine />} />

      <Route path="/certificates" element={<Certificates />} />
      <Route path="/certificates/:id" element={<SingleCertificate />} />

      <Route path="/prescriptions" element={<Prescriptions />} />
      <Route path="/prescriptions/:id" element={<SinglePrescription />} />

      <Route path="/bookings" element={<Bookings />} />
      <Route path="/bookings/:id" element={<SingleBooking />} />

      <Route path="/login" element={<Login />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Routers;
