// imports.js
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import { AuthContext } from "../../../context/AuthContext";
import { PDFDownloadLink } from "@react-pdf/renderer";

import {
  fetchData,
  editData,
  getData,
  deleteData,
} from "../../../utils/functions";

// imports.jsx
import Sidebar from "../../../components/Admin/Sidebar/Sidebar";
import HandleErrors from "../../../utils/handleErrors";
import Document from "./Document";

const SinglePrescription = () => {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [prescription, setPrescriptions] = useState([]);
  const [medicines, setMedicines] = useState([]);
  const [selectedMedicine, setSelectedMedicine] = useState(
    prescription.medicines
  );
  const [error, setError] = useState(null);

  const [isLoadingPrescriptions, setIsLoadingPrescriptions] = useState(true);
  const [isLoadingUsers, setIsLoadingUsers] = useState(true);
  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [isLoadingMedicines, setIsLoadingMedicines] = useState(true);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    fetchData(
      "prescriptions",
      id,
      setPrescriptions,
      setIsLoadingPrescriptions,
      setError
    );
    getData("users", setUsers, setIsLoadingUsers, setError);
    getData("clients", setClients, setIsLoadingClients, setError);
    getData("medicines", setMedicines, setIsLoadingMedicines, setError);
  }, [id]);

  useEffect(() => {
    if (
      !isLoadingPrescriptions &&
      !isLoadingUsers &&
      !isLoadingClients &&
      !isLoadingMedicines
    ) {
      if (medicines.length > 0 && prescription.medicines.length > 0) {
        const initialSelectedMedicine = prescription.medicines;
        setSelectedMedicine(initialSelectedMedicine || "");
      }
    }
  }, [
    isLoadingPrescriptions,
    isLoadingUsers,
    isLoadingClients,
    isLoadingMedicines,
    medicines,
    prescription.medicines,
  ]);

  const notifySaveChanges = () => {
    setShowNotification(true);
    setTimeout(() => {
      setShowNotification(false);
    }, 10000);
  };

  const handleAddMedicine = () => {
    if (selectedMedicine) {
      setPrescriptions((prevFormData) => ({
        ...prevFormData,
        medicines: [...prevFormData.medicines, selectedMedicine],
      }));
      setSelectedMedicine("");
    }
  };

  const handleRemoveMedicine = (medicineId) => {
    setPrescriptions((prevFormData) => ({
      ...prevFormData,
      medicines: prevFormData.medicines.filter((id) => id !== medicineId),
    }));
  };

  const filteredUsers = users;

  const filteredClients = clients;

  const filteredMedicine = medicines;

  const navigate = useNavigate();

  const del = () => {
    deleteData(id, "prescriptions", navigate);
  };

  const edit = () => {
    prescription.updatedBy = user?.userData?.name;
    editData(id, "prescriptions", prescription);
    notifySaveChanges();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPrescriptions((prevprescription) => ({
      ...prevprescription,
      [name]: value,
    }));
  };

  return (
    <div
      className="admin"
      id="admin"
      style={{
        background: "url('../../assets/img/home.png') no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <Sidebar />

      <main id="main" className="main">
        {showNotification && (
          <div
            className="alert alert-info alert-dismissible fade show"
            style={{ position: "fixed", top: "80px", zIndex: 999 }}
            role="alert"
          >
            <i className="bi bi-info-circle me-1"></i>Alterações feitas!
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="alert"
              aria-label="Close"
            ></button>
          </div>
        )}
        <div className="pagetitle">
          <h1>Visualização</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="/admin">Início</a>
              </li>
              <li className="breadcrumb-item">
                <a href="/prescriptions">Receitas</a>
              </li>
              <li className="breadcrumb-item active">Painel</li>
            </ol>
          </nav>
        </div>
        <div className="container">
          <section className="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-md-6 d-flex flex-column align-items-center justify-content-center">
                  {isLoadingPrescriptions ? (
                    <></>
                  ) : isLoadingClients ? (
                    <></>
                  ) : isLoadingMedicines ? (
                    <></>
                  ) : isLoadingUsers ? (
                    <></>
                  ) : (
                    <div className="card mb-3">
                      <div className="card-body">
                        <div className="pt-4 pb-2">
                          <h5 className="card-title text-center pb-0 fs-4">
                            Visualização
                          </h5>
                          <p className="text-center small">Receita</p>
                        </div>

                        {error && <HandleErrors err={error}></HandleErrors>}

                        <form className="row g-3" action="">
                          <div className="col-12">
                            <label htmlFor="client" className="form-label">
                              Paciente
                            </label>
                            <div className="input-group">
                              <select
                                name="client"
                                id="client"
                                className="form-control"
                                value={prescription.client}
                                onChange={handleInputChange}
                              >
                                {prescription.client && (
                                  <option value={prescription.client._id}>
                                    {prescription.client.name}
                                  </option>
                                )}
                                {filteredClients
                                  .filter(
                                    (client) =>
                                      client._id !== prescription.client?._id
                                  )
                                  .map((client) => (
                                    <option key={client._id} value={client._id}>
                                      {client.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          <div class="col-12">
                            <label for="user" class="form-label">
                              Dentista
                            </label>
                            <div class="input-group">
                              <select
                                name="user"
                                id="user"
                                className="form-control"
                                value={prescription.user}
                                onChange={handleInputChange}
                              >
                                {prescription.user && (
                                  <option value={prescription.user._id}>
                                    {prescription.user.name}
                                  </option>
                                )}
                                {filteredUsers
                                  .filter(
                                    (user) =>
                                      user._id !== prescription.user?._id
                                  )
                                  .map((user) => (
                                    <option key={user._id} value={user._id}>
                                      {user.name}
                                    </option>
                                  ))}
                              </select>
                            </div>
                          </div>

                          <div className="col-12">
                            <label htmlFor="medicine" className="form-label">
                              Medicamentos
                            </label>
                            <div className="input-group">
                              <select
                                name="medicine"
                                id="medicine"
                                className="form-control"
                                value={selectedMedicine}
                                onChange={(e) =>
                                  setSelectedMedicine(e.target.value)
                                }
                              >
                                <option value="">
                                  Selecione um medicamento
                                </option>
                                {filteredMedicine?.map((user, index) => (
                                  <option value={user._id}>{user.name}</option>
                                ))}
                              </select>
                              <button
                                type="button"
                                className="btn btn-primary"
                                onClick={handleAddMedicine}
                              >
                                Adicionar
                              </button>
                            </div>
                          </div>

                          <div>
                            <ul>
                              {prescription.medicines.map((medicineId) => {
                                const selectedMedicine = medicines.find(
                                  (medicine) => medicine._id === medicineId._id
                                );

                                if (selectedMedicine) {
                                  return (
                                    <div
                                      className="d-flex justify-content-between"
                                      key={medicineId}
                                    >
                                      <li>{selectedMedicine.name}</li>
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        onClick={() =>
                                          handleRemoveMedicine(medicineId)
                                        }
                                      >
                                        Remover
                                      </button>
                                    </div>
                                  );
                                }

                                return null;
                              })}
                            </ul>
                          </div>

                          <div className="col-6">
                            <label htmlFor="createdAt" className="form-label">
                              Criado em
                            </label>
                            <div className="input-group">
                              <p
                                name="createdAt"
                                className="form-control"
                                id="createdAt"
                              >
                                {moment(prescription.createdAt).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="col-6">
                            <label htmlFor="updatedAt" className="form-label">
                              Atualizado em
                            </label>
                            <div className="input-group">
                              <p
                                name="updatedAt"
                                className="form-control"
                                id="updatedAt"
                              >
                                {moment(prescription.updatedAt).format(
                                  "DD/MM/YYYY"
                                )}
                              </p>
                            </div>
                          </div>

                          <div className="col-4">
                            <button
                              className="btn btn-secondary w-100"
                              onClick={edit}
                              type="button"
                            >
                              Editar
                            </button>
                          </div>
                          <div className="col-4">
                            <button
                              className="btn btn-danger w-100"
                              onClick={del}
                              type="button"
                            >
                              Deletar
                            </button>
                          </div>
                          <div className="col-4">
                            <PDFDownloadLink
                              className="btn btn-primary w-100"
                              document={
                                <Document prescription={prescription} />
                              }
                              fileName="prescription.pdf"
                            >
                              {({ blob, loading, error }) =>
                                loading ? "Carregando..." : "Criar documento"
                              }
                            </PDFDownloadLink>
                          </div>
                        </form>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </div>
  );
};

export default SinglePrescription;
