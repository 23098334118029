import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const PhotoSelector = ({ photos, onSave, img }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);
  const handlePhotoClick = (photo) => setSelectedPhoto(photo);
  const savePhoto = () => {
    if (selectedPhoto) {
      onSave(selectedPhoto);
      closeModal();
    }
  };

  return (
    <div>
      <img
        src={img || "/assets/img/person/adulto-1.png"}
        alt="Profile"
        className="rounded-circle"
        onClick={openModal}
      />
      <Modal isOpen={isModalOpen} onRequestClose={closeModal} contentLabel="Select Profile Photo">
        <h2>Selecionar foto</h2>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          {photos.map((photo, index) => (
            <img
              key={index}
              src={photo}
              alt={`Profile ${index}`}
              onClick={() => handlePhotoClick(photo)}
              style={{
                width: '100px',
                height: '100px',
                margin: '5px',
                cursor: 'pointer',
                border: selectedPhoto === photo ? '2px solid black' : '2px solid transparent',
              }}
            />
          ))}
        </div>
        <button onClick={savePhoto} className="btn btn-primary" disabled={!selectedPhoto} style={{ marginTop: '20px' }}>
          Save
        </button>
        <button onClick={closeModal} className="btn btn-secondary" style={{ marginTop: '20px', marginLeft: '10px' }}>
          Cancel
        </button>
      </Modal>
    </div>
  );
};

export default PhotoSelector;
