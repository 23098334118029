import React, { useState, useEffect, useContext } from "react";
import "./calendar.css";
import FullCalendar from "@fullcalendar/react";
import ptBr from "@fullcalendar/core/locales/pt-br";
import Cookies from "js-cookie";
import axios from "axios";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { useNavigate } from "react-router-dom";
import listPlugin from "@fullcalendar/list";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Collapse,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  useTheme,
} from "@mui/material";
import { format } from "date-fns";
import { tokens } from "../../../theme";
import { getData } from "../../../utils/functions";
import { AuthContext } from "../../../context/AuthContext";
import BASE_URL from "../../../utils/config";

const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [agendamentos, setAgendamentos] = useState([]);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [isAllDay, setIsAllDay] = useState(false); // Estado para dia todo

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  // Estado para abrir e fechar o diálogo
  const [openDialog, setOpenDialog] = useState(false);

  // Estado para dados do evento no formulário
  const [formData, setFormData] = useState({
    client: "",
    user: "",
    createdBy: user?.userData?.name || "indefinido",
    updatedBy: user?.userData?.name || "indefinido",
    time_inicial: "",
    time_final: "",
    data: "",
  });

  const [selectedInfo, setSelectedInfo] = useState(null);

  useEffect(() => {
    getData("bookings", setAgendamentos, setIsLoading, setError);
  }, []);

  useEffect(() => {
    getData("users", setUsers, setIsLoading, setError);
    getData("clients", setClients, setIsLoading, setError);
  }, []);

  useEffect(() => {
    if (agendamentos.length > 0) {
      const eventos = agendamentos.map((agendamento) => ({
        title: `Paciente: ${agendamento?.client?.name}`,
        description: `Dr. ${agendamento?.user?.name}`,
        start: new Date(
          `${agendamento?.data.split("T")[0]}T${agendamento.time_inicial}`
        ),
        end: new Date(
          `${agendamento?.data.split("T")[0]}T${agendamento.time_final}`
        ),
      }));
      setCurrentEvents(eventos);
    }
  }, [agendamentos]);

  // Função para abrir o diálogo
  const handleDateClick = (selected) => {
    console.log(selected);

    setSelectedInfo(selected);
    setIsAllDay(selected.allDay); // Define se o evento é de dia todo
    const startHour = `${selected.start
      .getHours()
      .toString()
      .padStart(2, "0")}:${selected.start
      .getMinutes()
      .toString()
      .padStart(2, "0")}`; // Hora inicial
    const endHour = `${selected.end
      .getHours()
      .toString()
      .padStart(2, "0")}:${selected.start
      .getMinutes()
      .toString()
      .padStart(2, "0")}`; // Hora final

    setFormData({
      ...formData,
      data: selected.startStr,
      time_inicial: startHour, // Define a hora inicial
      time_final: endHour, // Define a hora final
    });
    setOpenDialog(true); // Abre o pop-up
  };

  const handleAllDayChange = (event) => {
    setIsAllDay(event.target.checked);
    // Limpa os horários se for "dia todo"
    if (event.target.checked) {
      setFormData({
        ...formData,
        time_inicial: "",
        time_final: "",
      });
    }
  };

  const handleSubmit = async () => {
    if (
      !formData.client ||
      !formData.user ||
      (!isAllDay && (!formData.time_inicial || !formData.time_final))
    ) {
      setError(true);
      return;
    }

    setOpenDialog(false);

    // Adicionar evento no calendário
    const calendarApi = selectedInfo.view.calendar;
    calendarApi.addEvent({
      id: `${selectedInfo.dateStr}-${formData.client}`,
      title: `Paciente: ${formData.client}`,
      start: `${selectedInfo.startStr}T${formData.time_inicial}`,
      end: `${selectedInfo.startStr}T${formData.time_final}`,
      allDay: isAllDay,
    });

    // Enviar dados para o banco de dados
    try {
      const token = Cookies.get("accessToken");
      await axios.post(`${BASE_URL.key}/bookings`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (err) {
      setError(true);
    }
  };

  const handleEventClick = (selected) => {
    if (window.confirm(`Desmarcar paciente: '${selected.event.title}'`)) {
      selected.event.remove();
    }
  };

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between">
        {/* CALENDAR SIDEBAR */}
        <Box
          flex="1 1 20%"
          backgroundColor={colors.primary[400]}
          p="15px"
          borderRadius="4px"
        >
          <Typography variant="h5">Eventos</Typography>
          <List>
            {currentEvents.map((event) => (
              <ListItem
                key={event.id}
                sx={{
                  backgroundColor: colors.greenAccent[500],
                  margin: "10px 0",
                  borderRadius: "2px",
                }}
              >
                <ListItemText
                  primary={event.title}
                  secondary={
                    <Typography>
                      {format(new Date(event.start), "dd/MM/yyyy")}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Box>

        {/* CALENDAR */}
        <Box flex="1 1 100%" ml="15px">
          <FullCalendar
            height="75vh"
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            locales={[ptBr]}
            locale={"pt-br"}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
            }}
            initialView="dayGridMonth"
            editable={true}
            selectable={true}
            selectMirror={true}
            dayMaxEvents={true}
            select={handleDateClick}
            eventClick={handleEventClick}
            eventsSet={(events) => setCurrentEvents(events)}
            initialEvents={currentEvents}
          />
        </Box>
      </Box>

      {/* Dialog para formulário */}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Adicionar Agendamento</DialogTitle>

        <DialogContent>
          <Typography
            variant="subtitle1"
            component="div"
            sx={{ marginBottom: "15px" }}
          >
            Data: {selectedInfo && format(selectedInfo.start, "dd/MM/yyyy")}
          </Typography>

          <FormControlLabel
            control={
              <Checkbox checked={isAllDay} onChange={handleAllDayChange} />
            }
            label="Dia todo"
          />

          {/* Transição suave para campos de horário */}
          <Collapse in={!isAllDay}>
            <TextField
              label="Horário Inicial"
              fullWidth
              value={formData.time_inicial || "00:00"}
              onChange={(e) =>
                setFormData({ ...formData, time_inicial: e.target.value })
              }
              margin="dense"
              type="time"
              sx={{ transition: "all 0.3s ease-in-out" }} // Transição suave
            />
            <TextField
              label="Horário Final"
              fullWidth
              value={formData.time_final || "00:00"}
              onChange={(e) =>
                setFormData({ ...formData, time_final: e.target.value })
              }
              margin="dense"
              type="time"
              sx={{ transition: "all 0.3s ease-in-out" }} // Transição suave
            />
          </Collapse>

          <TextField
            label="Paciente"
            fullWidth
            value={formData.client}
            onChange={(e) =>
              setFormData({ ...formData, client: e.target.value })
            }
            margin="dense"
          />
          <TextField
            label="Médico"
            fullWidth
            value={formData.user}
            onChange={(e) => setFormData({ ...formData, user: e.target.value })}
            margin="dense"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="secondary">
            Cancelar
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Salvar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Calendar;
