import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
} from "@mui/material";

export default function UserFormDialog({ open, onClose, user }) {
  const [newPrescription, setNewPrescription] = useState({
    client: "",
    user: "",
    createdBy: user?.userData?.name || "indefinido",
    updatedBy: user?.userData?.name || "indefinido",
    medicines: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewPrescription({ ...newPrescription, [name]: value });
  };

  const handleSubmit = () => {
    console.log("Novo usuário:", newPrescription);
    onClose(); // Fecha o diálogo
    setNewPrescription({
      name: "",
      phone: "",
      email: "",
      password: "",
      identity: "",
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Adicionar Receita</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="client"
          label="Paciente"
          type="text"
          fullWidth
          variant="outlined"
          value={newPrescription.client}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="user"
          label="Doutor"
          type="text"
          fullWidth
          variant="outlined"
          value={newPrescription.user}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="medicines"
          label="Medicamentos"
          type="text"
          fullWidth
          variant="outlined"
          value={newPrescription.medicines}
          onChange={handleInputChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit}>Enviar</Button>
      </DialogActions>
    </Dialog>
  );
}
