import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid2,
} from "@mui/material";
import moment from "moment";

export default function UserFormDialog({ open, onClose, user }) {
  const [newCertificate, setNewCertificate] = useState({
    client: "",
    user: "",
    createdBy: user?.userData?.name || "indefinido",
    updatedBy: user?.userData?.name || "indefinido",
    justification: "",
    time_inicial: moment(Date.now()).add(-1, "hour").format("HH:mm"),
    time_final: moment(Date.now()).format("HH:mm"),
    cid: "",
    return: "1",
    data: moment(Date.now()).format("YYYY-MM-DD"),
    data_documento: moment(Date.now()).format("YYYY-MM-DD"),
  });

  const [errors, setErrors] = useState({ return: false });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "return") {
      if (value < 0) {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: true }));
        return;
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, [name]: false }));
      }
    }

    setNewCertificate({ ...newCertificate, [name]: value });
  };

  const handleSubmit = () => {
    console.log("Novo atestado:", newCertificate);
    onClose(); // Fecha o diálogo
    setNewCertificate({
      client: "",
      user: "",
      justification: "",
      time_inicial: "",
      time_final: "",
      cid: "",
      return: "",
    });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Adicionar Atestado</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          name="client"
          label="Paciente"
          type="text"
          fullWidth
          variant="outlined"
          value={newCertificate.client}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          name="user"
          label="Dentista"
          type="text"
          fullWidth
          variant="outlined"
          value={newCertificate.user}
          onChange={handleInputChange}
        />

        <FormControl fullWidth margin="dense">
          <InputLabel>Justificativa</InputLabel>
          <Select
            name="justification"
            value={newCertificate.justification}
            onChange={handleInputChange}
            label="Justificativa"
          >
            <MenuItem value="law">Jurídico</MenuItem>
            <MenuItem value="job">Trabalho</MenuItem>
            <MenuItem value="school">Escolar</MenuItem>
            <MenuItem value="militar">Militar</MenuItem>
            <MenuItem value="other">Outro</MenuItem>
          </Select>
        </FormControl>

        <Grid2 container spacing={0.8}>
          <Grid2 item size={4}>
            <TextField
              margin="dense"
              name="time_inicial"
              label="Início"
              type="time"
              fullWidth
              variant="outlined"
              value={newCertificate.time_inicial}
              onChange={handleInputChange}
            />
          </Grid2>
          <Grid2 item size={4}>
            <TextField
              margin="dense"
              name="time_final"
              label="Término"
              type="time"
              fullWidth
              variant="outlined"
              value={newCertificate.time_final}
              onChange={handleInputChange}
            />
          </Grid2>
          <Grid2 item size={2}>
            <TextField
              margin="dense"
              name="cid"
              label="CID"
              type="text"
              fullWidth
              variant="outlined"
              value={newCertificate.cid}
              onChange={handleInputChange}
              required
            />
          </Grid2>
          <Grid2 item size={2}>
            <TextField
              margin="dense"
              name="return"
              label="Retorno"
              type="number"
              fullWidth
              variant="outlined"
              value={newCertificate.return}
              onChange={handleInputChange}
              error={errors.return}
              helperText={errors.return ? "Maior ou igual a 0" : ""}
              required
            />
          </Grid2>
        </Grid2>
        <Grid2 container spacing={0.8}>
          <Grid2 item size={6}>
            <TextField
              margin="dense"
              name="data"
              label="Data"
              type="date"
              fullWidth
              variant="outlined"
              value={newCertificate.data}
              onChange={handleInputChange}
            />
          </Grid2>
          <Grid2 item size={6}>
            <TextField
              margin="dense"
              name="data_documento"
              label="Data do documento"
              type="date"
              fullWidth
              variant="outlined"
              value={newCertificate.data_documento}
              onChange={handleInputChange}
            />
          </Grid2>
        </Grid2>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button onClick={handleSubmit}>Enviar</Button>
      </DialogActions>
    </Dialog>
  );
}
