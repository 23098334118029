import React, { useState, useEffect } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import BASE_URL from "../../../utils/config";
import moment from "moment";
import Modal from "react-modal";
import "./upload.css";

Modal.setAppElement("#root");

export default function Uploads({ clientId }) {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null); // Estado para o arquivo selecionado
  const [showModal, setShowModal] = useState(false); // Controle do modal
  const [formData, setFormData] = useState({
    client: clientId,
    file: null,
    filename: "",
    updateBy: undefined,
  });

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const token = Cookies.get("accessToken");
        const { data } = await axios.get(
          `${BASE_URL.key}/uploads/client/${clientId}`,
          {
            headers: { Authorization: `${token}` },
          }
        );
        console.log(data); // Verifique aqui se os dados estão corretos
        setFiles(data);
      } catch (err) {
        console.error("Erro ao carregar arquivos:", err);
        if (err.response.status === 404) {
          return setError("Nenhum arquivo encontrado.");
        }
        setError("Erro ao carregar arquivos.");
      }
    };

    fetchFiles();
  }, [clientId]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files ? files[0] : value, // Se for o input de file, armazena o arquivo
    }));
    setError("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!formData.file) {
      setError("Por favor, selecione um arquivo.");
      return;
    }

    try {
      const token = Cookies.get("accessToken");
      setUploading(true);
      setError(null);
      setSuccess(null);

      // Criação de um FormData para incluir o arquivo e outros campos
      const fileData = new FormData();
      fileData.append("file", formData.file);
      fileData.append("filename", formData.filename);
      fileData.append("client", clientId);

      // Console log para ver os dados antes de enviar
      for (let pair of fileData.entries()) {
        console.log(`${pair[0]}: ${pair[1]}`);
      }

      // Envio do arquivo para o backend
      await axios.post(`${BASE_URL.key}/uploads`, fileData, {
        headers: {
          Authorization: `${token}`,
          "Content-Type": "multipart/form-data", // Garantir que o axios entenda o multipart
        },
      });

      setSuccess("Arquivo enviado com sucesso!");
    } catch (err) {
      console.error("Erro ao enviar o arquivo:", err);
      setError("Erro ao enviar o arquivo.");
    } finally {
      setUploading(false);
    }
  };

  const handleDelete = async (fileId) => {
    try {
      const token = Cookies.get("accessToken");
      setDeleting(fileId); // Marcar o arquivo que está sendo deletado
      await axios.delete(`${BASE_URL.key}/uploads/${fileId}`, {
        headers: { Authorization: `${token}` },
      });

      setFiles(files.filter((file) => file._id !== fileId));
      setSuccess("Arquivo deletado com sucesso!");
    } catch (err) {
      console.error("Erro ao deletar o arquivo:", err);
      setError("Erro ao deletar o arquivo.");
    } finally {
      setDeleting(""); // Resetar o estado de deletando após completar
    }
  };

  const renderFileLink = (file) => {
    const isImage = file.mimetype.includes("image");
    const isPdf = file.mimetype.includes("pdf");

    return (
      <div key={file._id} className="file-item">
        {isImage && (
          <img
            src={file.url}
            alt={file.filename}
            style={{ maxWidth: "200px", maxHeight: "200px" }}
          />
        )}
        {isPdf && (
          <iframe
            src={file.url}
            title={file.filename}
            style={{ width: "100%", height: "400px" }}
          />
        )}
        <a href={file.url} target="_blank" rel="noopener noreferrer">
          {file.filename}
        </a>
        <button
          className="btn btn-danger"
          onClick={() => handleDelete(file._id)}
          disabled={deleting === file._id}
        >
          {deleting === file._id ? "Deletando..." : "Deletar"}
        </button>
      </div>
    );
  };

  const handleOpenModal = (file) => {
    setSelectedFile(file);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedFile(null);
    setShowModal(false);
  };

  return (
    <div>
      <section>
        <div className="row">
          <div className="card pt-4">
            <div className="card-body">
              {error && (
                <div
                  className="alert alert-danger alert-dismissible"
                  role="alert"
                >
                  {error}
                  <button class="btn-close" data-bs-dismiss="alert"></button>
                </div>
              )}
              {success && (
                <div
                  className="alert alert-success alert-dismissible"
                  role="alert"
                >
                  {success}
                  <button class="btn-close" data-bs-dismiss="alert"></button>
                </div>
              )}
              <form
                onSubmit={handleSubmit}
                encType="multipart/form-data"
                className="row g-3"
              >
                <div className="input-group">
                  <input
                    type="file"
                    name="file"
                    className="form-control"
                    id="file"
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    name="filename"
                    className="form-control"
                    id="filename"
                    value={formData.filename}
                    onChange={handleInputChange}
                    placeholder="Nome do Arquivo"
                  />
                </div>
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={uploading}
                >
                  {uploading ? "Enviando..." : "Upload"}
                </button>
              </form>
            </div>
          </div>

          <div className="row">
            <div className="card">
              <div className="card-body">
                <h2>Arquivos enviados</h2>
                <table className="table table-borderless datatable">
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Nome do arquivo</th>
                      <th scope="col">Tamanho</th>
                      <th scope="col">Data de Upload</th>
                      <th scope="col">Tipo de arquivo</th>
                      <th scope="col">Atualizado por</th>
                      <th scope="col">Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {files.length > 0 ? (
                      files.map((file, index) => (
                        <tr key={file._id}>
                          <th scope="row">{index + 1}</th>
                          <td>{file?.fileName}</td>
                          <td>
                            {(file?.size / (1024 * 1024)).toFixed(2)} MB(s)
                          </td>
                          <td>
                            {moment(file?.uploadDate).format("DD/MM/YYYY")}
                          </td>
                          <td>{file?.mimetype}</td>
                          <td>{file?.updateBy}</td>
                          <td>
                            <a
                              className="icon btn btn-primary"
                              href="/admin"
                              data-bs-toggle="dropdown"
                            >
                              <i className="bi bi-three-dots"></i>
                            </a>
                            <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow filter">
                              <li className="dropdown-header text-start">
                                <h6>Ação</h6>
                              </li>
                              <li>
                                <a className="dropdown-item" href={file?.url}>
                                  Baixar
                                </a>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  onClick={() => handleOpenModal(file)}
                                >
                                  Ver
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item btn btn-danger"
                                  onClick={() => handleDelete(file._id)}
                                >
                                  Deletar
                                </button>
                              </li>
                            </ul>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <Modal
          className="modal fade show d-block"
          tabIndex="-1"
          role="dialog"
          isOpen={showModal}
          onRequestClose={handleCloseModal}
          contentLabel="Documento"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{selectedFile?.fileName}</h5>
                <button
                  type="button"
                  className="btn close"
                  onClick={handleCloseModal}
                >
                  <span>&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {selectedFile?.mimetype.includes("image") && (
                  <img
                    src={selectedFile?.url}
                    alt={selectedFile?.filename}
                    style={{ width: "100%" }}
                  />
                )}
                {selectedFile?.mimetype.includes("pdf") && (
                  <object
                    data={selectedFile?.url}
                    type="application/pdf"
                    width="100%"
                    height="500px"
                  >
                    <p>
                      Seu navegador não suporta visualização de PDFs.
                      <a
                        href={selectedFile?.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Baixe o PDF
                      </a>
                      .
                    </p>
                  </object>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={handleCloseModal}
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </Modal>
      </section>
    </div>
  );
}
