import React from "react";
import "../../styles/admin.css";
import Admin from "./Admin";
import Calendar from "../../components/Admin/Calendar/Calendar";
import Title from "../../components/Admin/Title/Title";
import { Box } from "@mui/material";

const Dashboard = () => {
  return (
    <Admin>
      <Box m="20px">
        <Title subtitle={"Área administrativa"} title={"Painel Geral"} />

        <Box>
          <Calendar />
        </Box>
      </Box>
    </Admin>
  );
};

export default Dashboard;
