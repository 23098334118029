import React, { useState, useEffect } from "react";
import { getData } from "../../../utils/functions";
import {
  Card,
  CardContent,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  CircularProgress,
  Box,
  useTheme,
} from "@mui/material";
import { tokens } from "../../../theme";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PeopleIcon from "@mui/icons-material/People";

const NumberTotal = ({ route }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filterTerm, setFilterTerm] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    getData(route, setData, setIsLoading, setError);
  }, [route]);

  // Traduz a rota para uma string mais amigável
  const filteredRoute = (e) => {
    switch (e) {
      case "certificates":
        return "Atestados";
      case "users":
        return "Funcionários";
      case "clients":
        return "Pacientes";
      case "prescriptions":
        return "Receitas";
      case "medicines":
        return "Medicamentos";
      case "bookings":
        return "Agendamentos";
      default:
        return "Outros";
    }
  };

  // Lógica de filtro para data
  const handleFilter = (event) => {
    const clickedFilter = [
      event.currentTarget.getAttribute("data-filter"),
      event.currentTarget.innerText,
    ];
    setFilterTerm(clickedFilter);
    setAnchorEl(null); // Fecha o menu após a seleção
  };

  // Função para filtrar os dados com base na data
  const filterDataByDate = (date) => {
    if (!filterTerm[0]) return true;

    const today = new Date();
    const DateObj = new Date(date);

    switch (filterTerm[0]) {
      case "day":
        return (
          DateObj.getDate() === today.getDate() &&
          DateObj.getMonth() === today.getMonth() &&
          DateObj.getFullYear() === today.getFullYear()
        );
      case "month":
        return (
          DateObj.getMonth() === today.getMonth() &&
          DateObj.getFullYear() === today.getFullYear()
        );
      case "year":
        return DateObj.getFullYear() === today.getFullYear();
      default:
        return true;
    }
  };

  // Dados filtrados
  const filteredData = data.filter((data) => filterDataByDate(data.updatedAt));

  // Controle do menu de filtro
  const open = Boolean(anchorEl);

  return (
    <Card
      sx={{
        position: "relative",
        border: "2px solid #d5d5ff",
        borderRadius: 2,
        backgroundColor: colors.primary[400],
      }}
    >
      <Box sx={{ position: "absolute", right: 0 }}>
        <IconButton
          onClick={(event) => setAnchorEl(event.currentTarget)}
          size="large"
        >
          <MoreVertIcon />
        </IconButton>
        <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
          <MenuItem data-filter="day" onClick={handleFilter}>
            <ListItemText primary="No dia" />
          </MenuItem>
          <MenuItem data-filter="month" onClick={handleFilter}>
            <ListItemText primary="Este mês" />
          </MenuItem>
          <MenuItem data-filter="year" onClick={handleFilter}>
            <ListItemText primary="Este ano" />
          </MenuItem>
        </Menu>
      </Box>

      <CardContent>
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ color: colors.grey[100] }}
        >
          {filteredRoute(route)}{" "}
          <span style={{ fontWeight: "normal" }}>
            | {filterTerm[1] ? filterTerm[1] : "Este ano"}
          </span>
        </Typography>

        <Box display="flex" alignItems="center" mt={2}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 50,
              height: 50,
              borderRadius: "50%",
              backgroundColor: colors.blueAccent[600],
            }}
          >
            <PeopleIcon fontSize="large" />
          </Box>

          <Box pl={3}>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <Typography
                variant="h3"
                fontWeight="bold"
                sx={{ color: colors.greenAccent[300] }}
              >
                {filteredData.length}
              </Typography>
            )}
            {error && <Typography color="error">Algo deu errado.</Typography>}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default NumberTotal;
