import React, { useEffect } from "react";
import select from "../../../utils/select";
import on from "../../../utils/on";
import offscroll from "../../../utils/offScroll";
import onscroll from "../../../utils/onScroll";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";

import { useContext } from "react";
import { ColorModeContext } from "../../../theme";
import { useTheme, IconButton } from "@mui/material";

export default function Header() {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);

  useEffect(() => {
    const scrollto = (el) => {
      let header = select("#header");
      if (header) {
        let offset = header.offsetHeight;
        if (!header.classList.contains("header-scrolled")) {
          offset -= 20;
        }
        let elementPos = select(el).offsetTop;
        window.scrollTo({
          top: elementPos - offset,
          behavior: "smooth",
        });
      }
    };

    const navbarlinksActive = () => {
      const position = window.scrollY + 200;
      const navbarlinks = select("#navbar .scrollto", true);
      navbarlinks.forEach((navbarlink) => {
        if (!navbarlink.hash) return;
        const section = select(navbarlink.hash);
        if (!section) return;
        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarlink.classList.add("active");
        } else {
          navbarlink.classList.remove("active");
        }
      });
    };

    if (select(".mobile-nav-toggle")) {
      on("click", ".mobile-nav-toggle", function (e) {
        select("#navbar").classList.toggle("navbar-mobile");
        this.classList.toggle("bi-list");
        this.classList.toggle("bi-x");
      });
    }

    if (select("#navbar")) {
      on(
        "click",
        ".navbar .dropdown > a",
        function (e) {
          if (select("#navbar").classList.contains("navbar-mobile")) {
            e.preventDefault();
            this.nextElementSibling.classList.toggle("dropdown-active");
          }
        },
        true
      );
    }

    if (select(".scrollto")) {
      on(
        "click",
        ".scrollto",
        function (e) {
          if (select(this.hash)) {
            e.preventDefault();
            let navbar = select("#navbar");
            if (navbar.classList.contains("navbar-mobile")) {
              navbar.classList.remove("navbar-mobile");
              let navbarToggle = select(".mobile-nav-toggle");
              navbarToggle.classList.toggle("bi-list");
              navbarToggle.classList.toggle("bi-x");
            }
            scrollto(this.hash);
          }
        },
        true
      );
    }

    const handleScroll = () => {
      const header = select("#header");
      if (header) {
        if (window.scrollY > 100) {
          header.classList.add("header-scrolled");
        } else {
          header.classList.remove("header-scrolled");
        }
      }
    };

    const handleHashChange = () => {
      const hash = window.location.hash;
      if (hash) {
        const element = select(hash);
        if (element) {
          scrollto(hash);
        }
      }
    };

    onscroll(document, navbarlinksActive);
    onscroll(document, handleScroll);
    window.addEventListener("hashchange", handleHashChange);

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
      offscroll(document, navbarlinksActive);
      offscroll(document, handleScroll);
    };
  }, []);

  return (
    <header id="header" className="fixed-top">
      <div className="container d-flex align-items-center">
        <h1 className="logo me-auto">
          <a href="/">Mend's Clin</a>
        </h1>
        <nav id="navbar" className="navbar order-last order-lg-0">
          <ul>
            <li>
              <a className="nav-link scrollto active" href="#hero">
                Início
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="#services">
                Serviços
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                href="https://wa.me/5528992740822"
              >
                Contato
              </a>
            </li>
            <li>
              <a className="nav-link scrollto" href="/login">
                Login
              </a>
            </li>
            <li>
              <IconButton onClick={colorMode.toggleColorMode}>
                {theme.palette.mode === "dark" ? (
                  <DarkModeOutlinedIcon color="secondary" />
                ) : (
                  <LightModeOutlinedIcon />
                )}
              </IconButton>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
      </div>
    </header>
  );
}
