import React from "react";

export default function Faq() {
  return (
    <>
      <section id="faq" class="faq section-bg">
        <div class="container">
          <div class="section-title">
            <h2>Perguntas frequentes</h2>
            <p>Se caso houver mais dúvidas não hesite em entrar em contato!</p>
          </div>

          <div class="faq-list">
            <ul>
              <li data-aos="fade-up">
                <i class="bx bx-help-circle icon-help"></i>
                <a
                  data-bs-toggle="collapse"
                  class="collapse"
                  data-bs-target="#faq-list-1"
                  href="#faq"
                >
                  Quais são os serviços oferecidos pelo consultório?
                  <i class="bx bx-chevron-down icon-show"></i>
                  <i class="bx bx-chevron-up icon-close"></i>
                </a>
                <div
                  id="faq-list-1"
                  class="collapse show"
                  data-bs-parent=".faq-list"
                >
                  <p>
                    Oferecemos uma ampla gama de serviços odontológicos,
                    incluindo limpeza e profilaxia, restaurações, tratamento de
                    canal, próteses dentárias, implantes, ortodontia e estética
                    dental, entre outros. non.
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="100">
                <i class="bx bx-help-circle icon-help"></i>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq-list-2"
                  class="collapsed"
                  href="#faq"
                >
                  Como posso agendar uma consulta?
                  <i class="bx bx-chevron-down icon-show"></i>
                  <i class="bx bx-chevron-up icon-close"></i>
                </a>
                <div
                  id="faq-list-2"
                  class="collapse"
                  data-bs-parent=".faq-list"
                >
                  <p>
                    Você pode agendar uma consulta entrando em contato conosco
                    por telefone ou através do nosso site. Nossa equipe terá
                    prazer em ajudá-lo a encontrar o melhor horário para sua
                    consulta.
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="200">
                <i class="bx bx-help-circle icon-help"></i>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq-list-3"
                  class="collapsed"
                  href="#faq"
                >
                  Quais são os horários de atendimento do consultório?
                  <i class="bx bx-chevron-down icon-show"></i>
                  <i class="bx bx-chevron-up icon-close"></i>
                </a>
                <div
                  id="faq-list-3"
                  class="collapse"
                  data-bs-parent=".faq-list"
                >
                  <p>
                    Estamos abertos de segunda a sexta-feira, das 8h às 16h.
                    Para horários específicos, recomendamos entrar em contato
                    para verificar a disponibilidade.
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="300">
                <i class="bx bx-help-circle icon-help"></i>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq-list-4"
                  class="collapsed"
                  href="#faq"
                >
                  Quais formas de pagamento são aceitas?
                  <i class="bx bx-chevron-down icon-show"></i>
                  <i class="bx bx-chevron-up icon-close"></i>
                </a>
                <div
                  id="faq-list-4"
                  class="collapse"
                  data-bs-parent=".faq-list"
                >
                  <p>
                    Aceitamos pagamentos em dinheiro, cartões de débito e
                    crédito das principais bandeiras, além de oferecermos opções
                    de parcelamento e convênios com alguns planos de saúde
                    odontológicos.
                  </p>
                </div>
              </li>

              <li data-aos="fade-up" data-aos-delay="400">
                <i class="bx bx-help-circle icon-help"></i>
                <a
                  data-bs-toggle="collapse"
                  data-bs-target="#faq-list-5"
                  class="collapsed"
                  href="#faq"
                >
                  Qual é a política de cancelamento de consultas?
                  <i class="bx bx-chevron-down icon-show"></i>
                  <i class="bx bx-chevron-up icon-close"></i>
                </a>
                <div
                  id="faq-list-5"
                  class="collapse"
                  data-bs-parent=".faq-list"
                >
                  <p>
                    Pedimos que nos avise com antecedência caso precise cancelar
                    ou reagendar sua consulta. Isso nos permite organizar melhor
                    nossa agenda e atender outros pacientes que necessitem de
                    atendimento.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
}
