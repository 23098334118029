import React, { useState, useEffect, useContext } from "react";
import { getData, deleteData } from "../../../utils/functions"; // Função deleteData para remover usuários
import { Box, Button, Typography, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ptBR } from "@mui/x-data-grid/locales";
import moment from "moment";
import { maskBr } from "js-brasil";
import { AuthContext } from "../../../context/AuthContext";
import { tokens } from "../../../theme";
import Header from "../../../components/Admin/Title/Title";
import NumberTotal from "../../../components/Admin/Cards/NumberTotal";
import Admin from "../Admin";
import CustomToolbar, { CustomFooter } from "../../../utils/CustomToolbar.js";
import UserFormDialog from "./UserFormDialog";
import { formatStatus } from "../../../components/Admin/Validation/Validation.jsx";

export default function Users() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { user } = useContext(AuthContext);

  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false); // Controle do diálogo
  const [selectedRows, setSelectedRows] = useState([]); // Armazena as linhas selecionadas
  const [viewUser, setViewUser] = useState(null); // Armazena o usuário para visualizar

  // Filtra e formata os usuários
  const filteredUsers = users
    .filter((user) =>
      user?.name?.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .map((data, index) => ({ ...data, id: index + 1 }));

  // Colunas da tabela
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Nome",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "tel",
      headerName: "Telefone",
      flex: 1,
      valueGetter: (params) => maskBr.celular(params),
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      valueGetter: (params) => params || "N/A",
    },
    { field: "updatedBy", headerName: "Atualizado por", flex: 1 },
    {
      field: "identity",
      headerName: "CPF",
      flex: 1,
      valueGetter: (params) => maskBr.cpf(params),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      valueGetter: (params) => formatStatus(params),
    },
    {
      field: "updatedAt",
      headerName: "Data",
      flex: 1,
      valueGetter: (params) => moment(params).format("DD/MM/YYYY") || "N/A",
    },
  ];

  const handleDelete = async () => {
    try {
      selectedRows.map((rowId) => {
        const user = filteredUsers.find((user) => user.id === rowId);
        return deleteData(user._id, "users");
      });
    } catch (err) {
      console.error("Erro ao deletar:", err);
    }
  };

  // Função para abrir a visualização de um usuário
  const handleView = () => {
    if (selectedRows.length === 1) {
      const selectedUser = filteredUsers.find(
        (user) => user.id === selectedRows[0]
      );
      setViewUser(selectedUser);
      setOpenDialog(true);
    } else {
      setError("Selecione um único usuário para visualizar.");
      setViewUser("");
    }
  };

  useEffect(() => {
    getData("users", setUsers, setIsLoading, setError);
  }, []);

  const customToolbar = () => {
    return <CustomToolbar setOpenDialog={setOpenDialog} />;
  };

  const customFooter = () => {
    return (
      <CustomFooter
        selectedRows={selectedRows}
        handleDelete={handleDelete}
        handleView={handleView}
      />
    );
  };

  return (
    <Admin>
      <Box m="20px">
        <Header
          title="Usuários"
          subtitle="Lista de usuários cadastrados no sistema."
        />
        <Box display={"flex"}>
          <Box flex="0 0 30%">
            <NumberTotal route={"users"} />
          </Box>
        </Box>
        <Box
          height="75vh"
          sx={{
            "& .MuiDataGrid-root": { border: "none" },
            "& .MuiDataGrid-cell": { borderBottom: "none" },
            "& .name-column--cell": { color: colors.greenAccent[300] },
            "& .MuiDataGrid-columnHeaders": {
              backgroundColor: colors.blueAccent[700],
              borderBottom: "none",
            },
            "& .MuiDataGrid-virtualScroller": {
              backgroundColor: colors.primary[400],
            },
            "& .MuiDataGrid-footerContainer": {
              borderTop: "none",
              backgroundColor: colors.blueAccent[700],
            },
            "& .MuiCheckbox-root": {
              color: `${colors.greenAccent[200]} !important`,
            },
            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: `${colors.grey[100]}`,
            },
          }}
        >
          <DataGrid
            checkboxSelection
            rows={filteredUsers}
            columns={columns}
            initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
            pageSizeOptions={[5, 10, 25]}
            slots={{ toolbar: customToolbar, footer: customFooter }}
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            onRowSelectionModelChange={(ids) => setSelectedRows(ids)}
          />
        </Box>
        <UserFormDialog
          open={openDialog}
          onClose={() => {
            setOpenDialog(false);
            setViewUser("");
          }}
          setError={setError}
          setIsLoading={setIsLoading}
          user={user}
          viewUser={viewUser}
        />
      </Box>
    </Admin>
  );
}
