import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Formik } from "formik";
import * as yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { submitData } from "../../../utils/functions";

export default function UserFormDialog({
  open,
  onClose,
  user,
  setIsLoading,
  setError,
  viewUser,
}) {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const handleFormSubmit = (values) => {
    submitData("auth/register", values, setIsLoading, setError);
    onClose();
  };

  const validationSchema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório"),
    tel: yup
      .string()
      .matches(/^\d{10,15}$/, "Telefone inválido")
      .required("Telefone é obrigatório"),
    identity: yup
      .string()
      .max(15, "Limite de 15 caracteres")
      .required("Identidade é obrigatória"),
    email: yup.string().email("Email inválido").required("Email é obrigatório"),
    password: yup
      .string()
      .min(6, "Senha deve ter no mínimo 6 caracteres")
      .required("Senha é obrigatória"),
  });

  const initialValues = {
    name: viewUser?.name || "",
    createdBy: viewUser?.createdBy || user?.userData?.name || "indefinido",
    updatedBy: viewUser?.updatedBy || user?.userData?.name || "indefinido",
    tel: viewUser?.tel || "",
    email: viewUser?.email || "",
    password: viewUser?.password || "",
    identity: viewUser?.identity || "",
    terms: true,
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Adicionar Novo Usuário</DialogTitle>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleFormSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                autoFocus
                margin="dense"
                name="name"
                label="Nome"
                type="text"
                fullWidth
                variant="outlined"
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && !!errors.name}
                helperText={touched.name && errors.name}
              />
              <TextField
                margin="dense"
                name="tel"
                label="Telefone"
                type="text"
                fullWidth
                variant="outlined"
                value={values.tel}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.tel && !!errors.tel}
                helperText={touched.tel && errors.tel}
              />
              <TextField
                margin="dense"
                name="identity"
                label="Identidade"
                type="text"
                fullWidth
                variant="outlined"
                value={values.identity}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.identity && !!errors.identity}
                helperText={touched.identity && errors.identity}
              />
              <TextField
                margin="dense"
                name="email"
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                value={values.email}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
              />
              <TextField
                margin="dense"
                name="password"
                label="Senha"
                type={showPassword ? "text" : "password"}
                fullWidth
                variant="outlined"
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && !!errors.password}
                helperText={touched.password && errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={onClose}>Cancelar</Button>
              <Button type="submit">Enviar</Button>
            </DialogActions>
          </form>
        )}
      </Formik>
    </Dialog>
  );
}
