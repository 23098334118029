import React from "react";
import { Box, Button, useTheme, Typography } from "@mui/material";
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
  GridFooterContainer,
  GridPagination,
} from "@mui/x-data-grid";
import { tokens } from "../theme";

export default function CustomToolbar({ setOpenDialog }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
      <Box sx={{ flexGrow: 1 }} />
      {setOpenDialog ? (
        <Box>
          <Button
            sx={{
              color: `${colors.primary[400]} !important`,
              backgroundColor: colors.greenAccent[300],
            }}
            onClick={() => setOpenDialog(true)} // Abre o diálogo ao clicar
          >
            Adicionar
          </Button>
        </Box>
      ) : (
        <></>
      )}
    </GridToolbarContainer>
  );
}

export const CustomFooter = ({ selectedRows, handleDelete, handleView }) => (
  <GridFooterContainer>
    <Box
      flex="1"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      p={1}
    >
      {selectedRows.length !== 0 && (
        <>
          <Typography variant="body2">
            {selectedRows.length} linha(s) selecionada(s)
          </Typography>
          <Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDelete}
              disabled={selectedRows.length === 0}
              sx={{ mr: 2 }}
            >
              Deletar Selecionados
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleView}
              disabled={selectedRows.length !== 1}
            >
              Visualizar Selecionado
            </Button>
          </Box>
        </>
      )}
    </Box>
    <GridPagination />
  </GridFooterContainer>
);
